body {
  margin: 0;
  background-color: #1b2838;
  color: white;
  font-family: Motiva Sans, Arial, Helvetica, sans-serif;
}
header {
  margin-bottom: 50px;
  padding: 20px;
  background-color: #171a21;
}
header img {
  width: 100%;
  max-width: 450px;
}
h1 {
  font-size: 26px;
  line-height: 32px;
  text-overflow: ellipsis;
}

.App {
  text-align: center;
}

.search {
  height: 100px;
}

input[type="text"] {
  display: block;
  padding: 10px;
  font-size: 20px;
  border: 0;
  border-radius: 5px;
  width: 85%;
  max-width: 600px;
  margin: 10px auto;
}

.spinner {
  width: 200px;
  margin: auto;
  display: block;
}

.box {
  width: 90%;
  max-width: 600px;
  margin: 10px auto;
  padding: 0 10px 0 0;
  border: solid #293848 1px;
  border-radius: 3px;
}

.box:hover {
  border: solid #2b5066 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  transition: all 200ms ease-out;
  cursor: pointer;
}

.box img,
.box p {
  float: left;
  margin-right: 20px;
}

@media screen and (max-width: 480px) {
  .box img {
    float: left;
    width: 130px;
    margin-right: 10px;
  }
}

.clearfix {
  clear: both;
}
